import { Entry } from 'contentful'

export type ContentfulITag = Entry<{
  urlTag?: string
  name: string
}>

export type ITag = {
  id: string
  label: string
  urlTag: string
}

/**
 * Function to map Contentful Tags to an object with id and label
 * @param  {ContentfulITag[]} tags array of contentful Tags, Should contain a name field
 *
 * returns [{id: "test", label: "test label"}]
 */
export const mapTags = (tags: ContentfulITag[]): ITag[] =>
  !!tags &&
  tags
    .filter((tag) => tag && tag.fields?.name)
    .map((tag) => ({ label: tag.fields.name, id: tag.sys.id, urlTag: tag.fields.urlTag }))
